<template>
<div class="panel trade">
    

        
        <div v-if="trade.seller && !trade.replay">
          <div class="intro">
            <h1>{{ trade.seller.name }} {{ $store.getters.dictionary('needs your help') }}</h1>
            <div  v-html="$store.getters.dictionary('trade request')"></div>
          </div>
          <div class="seller-cards">
         
            <Card v-for="card in sellerSkills" :key="card.id" :card="card" :action="add"  > </Card>
            <Card v-for="card in sellerEquipment" :key="card.id" :card="card" :action="add"  > </Card>
          </div>
          <div class="seller-skills">
            
          </div>
          <button @click="reject('request')" class="reject"> Reject </button>
          <button @click="dotrade()"> {{ tradePrompt }} </button>
        </div>
        
        <div v-if="trade.pending && !trade.replay">
          <div class="intro">
            <h1>{{ trade.pending.title }}</h1>
           <p>{{ trade.pending.message }}</p>
          </div>
        </div>
        
        <div v-if="trade.reject && !trade.replay">
          <div class="intro">
            <h1>{{ trade.reject.title }} </h1>
           <p>{{ trade.reject.body }}</p>
          </div>
        </div>
        
        <div v-if="trade.complete && !trade.replay">
          <div class="intro">
            <h1>{{ trade.sellerName }} accepted your help</h1>
            <p v-html="$store.getters.dictionary('Drag the cards into slots')" v-if="finalCards > 0"></p>
          </div>
          <div class="seller-cards complete">
            <Card v-for="card in trade.cards" :key="card.id" :card="card" :action="add" :canDrag="true" > </Card>
          </div>
          <button @click="completed()" class="accept"  v-if="finalCards == 0"> Done </button>
        </div>
        
        <div v-if="trade.buyer && !trade.replay">
          <div class="intro">
            <h1 v-if="buyerEquipment.length > 0">Offer</h1>
            <h1 v-if="buyerEquipment.length < 1">{{ trade.buyer.name }} {{ $store.getters.dictionary('will help') }}</h1>
            <p v-if="buyerEquipment.length > 0">{{ trade.buyer.name }} {{ $store.getters.dictionary('is asking for') }}</p>
            <p v-if="buyerEquipment.length < 1">{{ trade.buyer.name }} {{ $store.getters.dictionary('has agreed') }}</p>
            <p v-if="buyerEquipment.length < 1"> {{ $store.getters.dictionary('accept help') }}</p>
            <p v-if="buyerEquipment.length > 0"> {{ $store.getters.dictionary('accept offer') }}</p>
          </div>
          <div class="seller-cards">
            <Card v-for="card in buyerEquipment" :key="card.id" :card="card" :action="add" > </Card>
          </div>
          <button @click="reject('offer')" class="reject"> Reject </button> <button @click="accept()" class="accept"> Accept </button>
        </div>

        <div v-if="trade.replay">
          <div class="intro">
            <h1>Trade underway</h1>
              <p v-if="trade.replay=='start'"> {{ trade.seller.name }} is asking {{ trade.buyer.name }} for help </p>
              
              <p v-if="trade.replay=='offer'"> {{ trade.seller.name }} is offering a trade to {{ trade.buyer.name }}</p>
              
              <p v-if="trade.replay=='accept'"> {{ trade.seller.name }} accepted {{ trade.buyer.name }}'s offer </p>
              
              <p v-if="trade.replay=='reject'"> {{ trade.seller.name }} rejected {{ trade.buyer.name }}'s offer </p>
              
              
             
          </div>
         <div class="buyer-cards" v-if="trade.cards">
           <Card v-for="card in trade.cards" :key="card.id" :card="card" :action="add" :canDrag="false" > </Card>
         </div>

        </div>
 
         
        
</div>
</template>

<script>

import Card from './Card.vue';


export default {
  name: 'Trade',
  props: {
    trade : Object
  },
  components : {
    Card
  },
  computed: {
    // a computed getter
    
    tradePrompt : function(){
      
      console.log('DCC', this.cards.length)
      
      if(this.cards.length > 0)
        return 'Offer trade'
      else
        return 'Offer help'
    },  
    finalCards : function(){
      console.log(this.trade.cards)
      return  this.trade.cards.filter( (card) => { 
      //  console.log(card)
        
        return card.type == 'equipment'
      
      }).length;
      
    } , 
    sellerSkills: function () {
    
      if(this.trade.seller)
        return this.$store.getters.equipment(this.trade.seller.skills);
      else 
        return [];
    
    },
    sellerEquipment: function () {
     
      var sellerCards = this.$store.getters.equipment(this.trade.seller.equipment);
      
      sellerCards = sellerCards.filter((card) => {
        

          return card.apprentice !== true;  

        
      });
      
      return sellerCards;
      
    }, buyerEquipment: function () {
     
      var buyerCards = [];
      
      if(this.trade.cards)
         buyerCards = this.$store.getters.equipment(this.trade.cards.map((card) => {return card.id}));
      
      
      
      
      return buyerCards;
      
    }
  },
  data : function(){
    
    return {
      expanded : false,
      cards : []
    }
    
  },
  methods: {
    add(card){
      if(this.cards.includes(card.id)){
        this.cards.splice(this.cards.indexOf(card.id),1);
        card.isActive = false
      }else{
        this.cards.push(card.id)
        card.isActive = true
      }
      
      console.log('buy',this.cards)
    },
    dotrade(){

      this.trade.pending = {
        title : 'Sending request to ' +this.trade.seller.name
      }
      var data = {
        seller : this.trade.seller.id,
        buyer : this.$store.state.id,
        cards : this.cards,
        action : 'offer'
      }
      this.$store.commit('trade',data)
      this.trade.seller = false;
      
    },
    accept(){
      var data = {
        seller : this.$store.state.id,
        buyer : this.trade.buyer.id,
        cards : this.trade.cards.map((card) => card.id),
        action : 'accept'
      }
      this.$store.commit('trade',data)
      },
    completed(){
      
      
      console.log('all done')
      this.$store.commit('clearTrade',{})
    },  
    close(){
        this.$store.commit('cancelTrade',{seller:this.$store.state.id});
    },
    reject(stage){
      var seller = null,
          buyer = null;
          
          
      if(stage == 'request'){
       seller = this.trade.seller.id;
        buyer = this.$store.state.id;
      }
      
      if(stage == 'offer'){
         seller = this.$store.state.id;
         buyer = this.trade.buyer.id;
      }

      console.log('reject', this.trade)
      var data = {
        'action' : 'reject',
        'stage' : stage,
        'seller' : seller,
        'buyer' : buyer,
        'cards' : [] 
      }
      
      console.log('reject',data)
      this.$store.commit('cancelTrade',data);
    },
    expand (event){
      event.preventDefault();
      this.expanded = !this.expanded;
      console.log('drop event',event)    
    },
    dragover (event){
   // event.preventDefault();
    console.log('drop event',event)    
  }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
