<template>
  <div class="profile" v-if="profile">
    
    <div class="profile-intro">
      <h1>{{title }}</h1>
      <p>{{ prompt }}</p>
    </div>
    
    <div class="profile-input">
      <Player v-bind:profile="preview" :context="'profile'" ></Player>
      
      <VueSlickCarousel class="avatar-carousel"  v-bind="carouselSettings">
        <img v-for="avatar in $store.getters.avatars" v-bind:key="avatar" v-bind:src="'avatars/' + avatar" class="avatar"  @click="setAvatar(avatar)"/>
    </VueSlickCarousel>
      <input v-model="preview.name" placeholder="Enter a name" name="name" type="text" class="userinput">
      <p class="error">{{ preview.error }}</p>
      <button @click="save" class="button">Next</button>
      
    </div>
 
  </div>
</template>

<script>


import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import Player from '../components/Player.vue';
export default {
  name: 'Profile',
  components: {
    Player,
    VueSlickCarousel
  },
  props: {
    msg: String
  },
  sockets: {

  },
  data : function(){
    
    return {
      title : 'Create your profile',
      prompt : this.$store.getters.dictionary('customise'),
      preview : {
        name: '',
        avatar : 'anonymous.png',
        error : ''
        },
      carouselSettings: {
        dots: false,
        slidesToScroll: 10,
        slidesToShow: 10
      }
    }
    
  },
  
  created (){
    var current = this.$store.getters.player
    this.preview.name = current.name;
    this.preview.avatar = current.avatar;
  },
  methods: {
    changed: function(event) {
      this.$store.commit('change', event.target.value)
    },
    
    setAvatar: function(src) {
      console.log('src',src)
      this.preview.avatar = src;
      this.$store.commit('profile',{'avatar' : src , 'name' : this.profile.name  })
    },
    
    save: function() {
      this.$store.commit('profile',{'name' : this.preview.name })
      
      if(this.preview.avatar !== 'anonymous.png' && this.preview.name !== '')
        this.$router.push('Lobby')
      else{
        
        if(this.preview.name == '')
            this.preview.error = 'Enter a name for your profile';
            
        if(this.preview.avatar == 'anonymous.png')
          this.preview.error = 'Pick an image for your profile';
       var self = this;
       
        setTimeout(function(){
          self.preview.error = '';
        },3000)
      
      }  
 
    }
    
  },
  computed: {
    profile : {
      get : function(){
        return this.$store.getters.player
      }
    },
    newname : {
        get : function(){
          return name;
        },
        
        set : function(new_name){
            this.$store.commit('add', new_name)
        }
    }
    
  }
}
</script>


