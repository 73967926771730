<template>
<div 
    @click="selectCard"
    :draggable="canDrag" 
	@dragstart="dragstart"
  @dragend="dragend"
    @drop="drop"
    class="card"
    ref="draggableContainer"
    :class="{ 'active' : isActive == true, 'extended' : extend, 'equipment' : card.type == 'equipment', 'skill' : card.type == 'skill'}">
    <div class="innerDrag">
        <div class="cardPadding" v-if="card">
          <h3> {{ card.name }}</h3>
          <p v-if="extend || card.expand ">{{card.description}}</p>
          
      </div>
     
    </div>
</div>    
</template>

<script>



export default {
  name: 'Card',
  props: {
    card: Object,
    action : Function,
    canDrag : Boolean,
    extend : Boolean
  },
  data : function() {
    
    return {
      isActive : false,
      positions: {
           clientX: undefined,
           clientY: undefined,
           movementX: 0,
           movementY: 0
          }
        
          
       }
  },
  methods: {
      selectCard: function() {
      
      if(this.action){
        this.action(this.card)
      }
      
      this.isActive = !this.isActive;
     
      },
	dragstart(event){
		this.isDragging = true;
    event.dataTransfer.effectAllowed = "move";
		event.dataTransfer.setData("text", this.card.id);
    console.log('drag start !',event.dataTransfer.types, this.card.id)
    document.body.classList.add('dragging');
		//event.dataTransfer.setData("selectedType", this.card.type);
	},
  dragend(){
    this.isDragging = false;
    console.log('drag end !')
   // event.dataTransfer.setData(this.card.id, this.card.id);
    document.body.classList.remove('dragging');
    //event.dataTransfer.setData("selectedType", this.card.type);
  },
    drop (event){
       event.preventDefault();
       document.body.classList.remove('dragging');
       console.log('drop event card',event)    
    }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
