import VueRouter from 'vue-router'
import Login from './views/Login.vue';
import Profile from './views/Profile.vue';
import Lobby from './views/Lobby.vue';
import Game from './views/Game.vue';
import Scoreboard from './views/Scoreboard.vue';

let routes = [
      { path: '/', name: 'login', component: Login },
      { path: '/profile', name: 'Profile', component: Profile },
      { path: '/lobby', name: 'Lobby', component: Lobby },
      { path: '/game', name: 'Game', component: Game },
      { path: '/scoreboard', name: 'Scoreboard', component: Scoreboard }
    
    ];
    
export  default new VueRouter({
  routes : routes
})