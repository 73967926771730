<template>
<div  class="panel stage" v-if="$store.getters.syncReady == true"

    :class="{'onboarding' : $store.getters.onboarding}"
>

        <Onboarding v-if="$store.getters.onboarding && !$store.state.Trade.trade"></Onboarding>
        
        <div v-if="!$store.state.Trade.trade">
          <transition name="slide"  mode="out-in">
            <div v-if="$store.getters.stage.client && !$store.getters.onboarding" class="treatment">
    
              <div class="intro">
                <h1>{{ serve.title }}</h1>
                <p>{{ serve.description }}</p>
              </div>
             
              <Client :client="$store.getters.client($store.getters.stage.client)" :expanded="true"></Client>
          
            </div>
          </transition>
          
          <transition name="slide"  mode="out-in">
            <Turn :turn="$store.getters.stage.turn" v-if="$store.getters.stage.turn && $store.getters.canPlay && !$store.getters.onboarding"></Turn>
          </transition>
        
        
          <div v-if="$store.getters.stage.equipment">
            {{ $store.getters.stage.equipment }}
          </div>
        
        <transition name="slide"  mode="out-in">
        <div v-if="$store.getters.firstRound && $store.getters.onboarding == false && $store.getters.canPlay" class="first-round">
          
          <div class="intro">
            <h1 v-html="$store.getters.dictionary('first round title')"></h1>
            <div v-html="$store.getters.dictionary('first round copy')"></div>
          </div>
          <div class="highlighter client-marker" v-html="$store.getters.dictionary('select-client')"></div>
        </div>
        </transition>
        
        <transition name="slide"  mode="out-in">
        <div v-if="$store.getters.firstRound && $store.getters.onboarding == false && !$store.getters.canPlay" class="first-round">
          
          <div class="intro">
            <h1 v-html="$store.getters.dictionary('their first round title')"></h1>
            <div v-html=" currentPlayer "></div>
          </div>
          
        </div>
        </transition>
          
          <a @click="endTurn" :disabled="waiting" class="end-turn" v-if="$store.getters.canPlay &&  !$store.getters.onboarding">End Turn</a>
        </div>
        
        
  
        
        <Trade  name="trade" :trade="$store.state.Trade.trade" v-if="$store.state.Trade.trade !== false"></Trade>

</div>	
</template>

<script>

import Onboarding from './Onboarding.vue';
import Client from './Client.vue';
import Trade from './Trade.vue';
import Turn from './Turn.vue';
import { bus } from '../main'



export default {
  name: 'Stage',
  props: {
    name: String,
  },
  created : function(){
    
    bus.$on('start-trade',() => {
      console.log('clear onboarding for trade', this)
      this.$store.commit('clearOnboarding', {})
    })

    
  },
  methods : {
    
    endTurn : function(){
      this.waiting = true;
      this.$store.commit('endTurn', {})
      

      
    }
    
  },
  computed : {
    
    serve(){
      
      var client = this.$store.getters.client(this.$store.getters.stage.client);
      var player = this.$store.getters.player;
      var currentPlayer = this.$store.getters.currentPlayer
      
      var  serve = {
        title : this.$store.getters.dictionary('serve-client'),
        description : this.$store.getters.dictionary('serve-client-copy').replace('[client]',client.name),  
        //'Do you have enough experience and the right equipment to treat '+client.name+'?',
        client : client
      }
      
      if(client.served === true){
        serve.title = this.$store.getters.dictionary('served-client');
        serve.description = this.$store.getters.dictionary('served-client-copy').replace('[client]',client.name)+ player.role;  
        //'You treated '+client.name+' and gained a promotion to '+ player.role+'!' 
      }
      
      if(!this.$store.getters.canPlay && currentPlayer !== false){
      
        serve = {
          title : this.$store.getters.dictionary('player-serving').replace('[player]',currentPlayer.name), //currentPlayer.name+' is examining ',
          description : this.$store.getters.dictionary('player-serving-copy').replace('[client]',client.name)
          //'Do they have enough experience and the right equipment to treat '+client.name+'?'
        }
        
        if(client.served === true){
          serve = {
            title : this.$store.getters.dictionary('player-served').replace('[player]',currentPlayer.name),
            description : this.$store.getters.dictionary('player-served-copy').replace('[level]',currentPlayer.level)
            //            'They have been promoted to  '+this.$store.getters.level(currentPlayer.level)+'!'
          }
        }
        
        
      }
      
      return serve;
      
    }
    ,
    'currentPlayer'(){
      
      return  this.$store.getters.dictionary('their first round copy').replace('[name]',this.$store.getters.currentPlayer.name); 
      
    }
  },
  data (){
    
    return {
      
      activeClient : false,
      trade : false,
      waiting : false
     
      
    }
    
  },
  components: {
    Client,
    Trade,
    Turn,
    Onboarding
  }
}
</script>


