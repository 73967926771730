<template>
    
    
    <div class="scoreboard">
        <h1>Scoreboard</h1>
        <p>Game over! How did you do on the leaderboard?</p>
       <Player v-for="player in players" v-bind:key="player.id"  
           :profile="player" v-on:starttrade="closeOverlay()" 
           :activated="player.id == overlay.id"
           :action="showOverlay" :extend="true" />
        
        <a @click="newGame()" class="new-game">New game</a>
    </div>

</template>
    
    <script>
    import { store } from '../store/store'
    import Player from '../components/Player.vue';

    
    export default {
      name: 'Scoreboard',
      store,
      created : function(){
          this.$confetti.start({defaultSize : 5});
          //this.$cookies.remove('session');
      },
      components: {
          Player
        },
      sockets: {
          connect: function () {
              console.log('socket connected scoreboard  ')
          },
          newuser: function (data) {
              console.log('broadcast',data)
            //  this.$store.commit('add', data)
          }
      },
      data : function(){
        
        return {
            overlay: false
        }
        
      },
      methods: {
        newGame : function (){
          this.$confetti.stop();
          this.$cookies.remove('session');
          this.$router.push('/');
          window.location.reload();  
        },        
        showOverlay : function(player){
              
              
              var profile = this.$store.getters.getProfile(player);
              console.log('p',profile)
              if(profile.assigned !== false){
                this.activated = true;
                this.overlay = profile; //this.$store.getters.getProfile(player);
              }
            }
            
      },
      computed: {
        
        players : function (){
            
            var players =  this.$store.getters.players(true);
            
           players = players.sort((a,b) => {
         
                return b.level - a.level;                
            })
      
            
            return players;
            
        }
      }
    }
    </script>

    