<template>
    <div class="modal"
        :class="{ 'active' : card.active}">
            <div class="padding">
              <h3> {{ card.title }}</h3>
              <p> {{ card.message }} </p>
              
              <button @click="close()" v-if="card.cta != false">{{ card.cta }}</button>
          </div>

    </div>    
    </template>
    
<script>

    
    export default {
      name: 'Modal',
      props: {
        card: Object,
        action : Function
      },
      created (){
      
      if(this.card.ticker){
          this.$confetti.start({defaultSize : 5});
          setTimeout(()=>{ this.$confetti.stop() },3 * 1000)
      }
      
      },
      data : function() {
      
      
          return {
          
          }
      
      },
      methods: {
         close(){
       
           if(this.card.action)
               this.card.action();
           
           this.card.active = false;
       
         }
      }
      
      
      }
      
</script>