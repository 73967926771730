<template>
    <div class="onboarding">
        <transition name="onboarding"  mode="out-in">
        <div class="slide slide-1" v-if="step ==1">
           <div class="intro">
               <h1 v-html="$store.getters.dictionary('onboard-one-title')"></h1>
               <p v-html="$store.getters.dictionary('onboard-one-copy')"></p>
           </div>
        </div>
        </transition>
        
        <transition name="onboarding"  mode="out-in">
        <div class="slide slide-2" v-if="step ==2">
            <div class="intro">
                   <h1 v-html="$store.getters.dictionary('onboard-two-title')"></h1>
                   <p v-html="$store.getters.dictionary('onboard-two-copy')"></p>
            </div>
            
            <div class="equipment-preview"> 
              
                <Card :card="dummyData.equipment" > </Card>
            </div>
            
            <div class="skills-preview"> 
                <Card :card="dummyData.skill" > </Card>
                
            </div>
            
            <div class="highlighter skill-marker">Your skills are shown here</div>
            <div class="highlighter equip-marker">Your equipment is shown here</div>
        </div>
        </transition>
        <transition name="onboarding"  mode="out-in">
       
       <div class="slide slide-3" v-if="step ==4">
           <div class="intro">
                  <h1 v-html="$store.getters.dictionary('onboard-three-title')"></h1>
                   <p v-html="$store.getters.dictionary('onboard-three-copy')"></p>
           </div>
           <div class="client-preview">
               <Client v-for="client in preview.clients" :key="client.id" :client="client" ></Client>
           </div>
           <div class="highlighter client-marker" v-html="$store.getters.dictionary('onboard-three-copy-b')"></div>
           
       </div>
        </transition>
       <transition name="onboarding"  mode="out-in">
       <div class="slide slide-4" v-if="step ==5">
              <div class="intro">
                    <h1 v-html="$store.getters.dictionary('onboard-four-title')"></h1>
                       <p v-html="$store.getters.dictionary('onboard-four-copy')"></p>
              </div>
              <div class="highlighter top player-marker"  v-html="$store.getters.dictionary('onboard-four-copy-b')"></div>
              <img class="onboard-image" src="images/onboarding/recruit.svg" />
          </div>
       </transition>
       <transition name="onboarding"  mode="out-in">
          <div class="slide slide-5" v-if="step ==6">
                <div class="intro">
                       <h1 v-html="$store.getters.dictionary('onboard-six-title')"></h1>
                          <p v-html="$store.getters.dictionary('onboard-six-copy')"></p>
                </div>
               <!--  <img class="onboard-image" src="images/onboarding/trade.svg" /> -->
                <div class="recruit-preview">
             <Card :card="dummyData.equipment" > </Card>
               <Card :card="dummyData.equipment" > </Card>
              
               <Card :card="dummyData.skill" > </Card>
              <div class="buttons">
                  
                  <button class="reject"> Reject </button>
                    <button > Offer trade </button>
              </div>
                  
              </div>
             
            </div>
       </transition>
       <transition name="onboarding"  mode="out-in">  
            <div class="slide slide-6" v-if="step ==7">
                  <div class="intro">
                         <h1 v-html="$store.getters.dictionary('onboard-seven-title')"></h1>
                            <p v-html="$store.getters.dictionary('onboard-seven-copy')"></p>
                  </div>
                  <div class="recruit-preview">
                      <Card :card="dummyData.equipment" > </Card>
                      <Card :card="dummyData.equipment" > </Card>
                  
                      <Card :card="dummyData.skill" > </Card>
                     
                     <div class="buttons">
                     <button class="reject"> Reject </button>
                     <button > Offer help</button>
                     </div>
                  </div>
                 <!-- <img class="onboard-image" src="images/onboarding/request.svg" /> -->
               
              </div>
       </transition>
       <transition name="onboarding"  mode="out-in">
              
              <div class="slide slide-7" v-if="step ==3">
                  <div class="intro">
                   <h1 v-html="$store.getters.dictionary('onboard-five-title')"></h1>
                     <p v-html="$store.getters.dictionary('onboard-five-copy')"></p>
                  </div>
                </div>
       </transition>
       <transition name="onboarding"  mode="out-in">
           <div class="slide slide-8" v-if="step ==8">
               <div class="intro">
                      <h1>Ready to play?</h1>
                      <p>Click on start to find out who has the first turn!</p>
                      <button @click="start()"  class="start">start</button>
               </div>
              
            
           </div>
       </transition>
           <div class="pagination">
                <button @click="prev()" v-if="step > 1" class="prev">←</button>
                <button @click="next()" v-if="step < 8" class="next">→</button>
                
           </div>
          
        
    </div>
</template>
    
<script>

    import Card from './Card';
    import Client from './Client';
    
 //   import { bus } from '../main'
    
    export default {
      name: 'Onboarding',
      components :{
          Card,
          Client
      },
      props: {
        card: Object,
        action : Function
      },
      data : function() {
      
      
          return {
              
              
          
              step : 1
          
          }
      
      },
      computed : {
      
          dummyData (){
              
              var dummies = {};
              
              var cards = this.$store.getters.cards;
              
              dummies.skill = cards.find((card ) => {
                  
                  return card.type == 'skill'
                  
              })
              
              dummies.equipment = cards.find((card ) => {
                    
                    return card.type == 'equipment'
                    
                })
              
              return dummies;
          },
      
          preview (){
          
               var profile = this.$store.getters.getProfile(this.$store.getters.id);
               console.log('p',profile)
               return {
                   equipment : profile.equipmentList.slice(0,2),
                   skills : this.$store.getters.skills.slice(0,2),
                   clients : this.$store.getters.clients(profile.clients).slice(0,1)
                   }
          }
      
      },
      methods: {
         next(){
       
              this.step++;

            
       
         },
         prev(){
    
           this.step--;

         
    
      },
         start(){
             this.$store.commit('start')
          
           
         
    
      }
      }
      
      
      }
      
</script>