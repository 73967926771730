import Vue from 'vue'
import Vuex from 'vuex'
import vm from '../main.js';
import router from '../router'
Vue.use(Vuex)


import { Trade } from './trade';

var messageTimeout = null;

export const store = new Vuex.Store({
 
 modules: {
   Trade: Trade
 }, 
 state: {
  id : false,
  isSpectator : false,
  syncReady : false,
  connectionState : false,
  room : false,
  gameOver : false,
  modal: {
    active : false,
    title : '',
    message : '',
    cta  : 'ok',
    action : false
  },
  theme: null,  
  name: 'test',
  onboarding: true,
  timer : 60 * 1000,
  stage :{
    client : false,
    turn : false,
    
  },
  avatars :["001-man.png","002-man.png","003-man.png","004-man.png","005-man.png","006-man.png","007-man.png","008-man.png","009-man.png","010-woman.png","011-woman.png","012-woman.png","013-woman.png","014-woman.png","015-woman.png","016-woman.png","017-woman.png","018-man.png","019-man.png","020-man.png","021-man.png","022-man.png","023-man.png","024-man.png","025-man.png","026-man.png","027-woman.png","028-woman.png","029-woman.png","030-woman.png","031-woman.png","032-woman.png","033-woman.png","034-woman.png","035-man.png","036-man.png","037-man.png","038-man.png","039-man.png","040-man.png","041-man.png","042-man.png","043-woman.png","044-woman.png","045-woman.png","046-woman.png","047-woman.png","048-woman.png","049-woman.png","050-woman.png"],
  players: [],
  team : [],
  activePlayer : null,
  currentPlayer: false,
  cards : [],
  levels : [],
  clients : [],
  nextID : 1,
  dictonary : {}
 },
 actions :{
   

   'upgrade'(commit, data){
     vm.$socket.emit('upgrade',data);
   }, 
   'selectClient'(commit, client){
       console.log('called selected client', client)
       
       if(!client){
         
         commit.commit('stage', client);
      
      }
       
       vm.$socket.emit('new-play',{stage : {client : client}});
   },
   'serveClient'(commit, client){
    //  console.log('serve client', client.name)
      commit.commit('serveClient',client);
  },
  'recrussit'(commit,data){
    
    vm.$socket.emit('trade',{buyer : data.buyer, seller : data.seller, card : false});
    //commit.commit('recruitid',id);
    console.log('recruit',{buyer : data.buyer, seller : data.seller, card : false})
    
  },
     
  'choosecard'(commit,data){
  
     
    vm.$socket.emit('choose-card',data);
  //  console.log('CHOOSE',data)
    
    
  },
  
  'message'(commit,message,action){
    
    commit.commit('SOCKET_message',message,action);
    
  },

 },
 mutations: {
  
  gameReset(state){
    console.log('reset game')
    state.room = false;
    state.Trade.trade = false;
    state.stage = {client : false, turn : false};
    state.onboarding = true;
    
 
  },
  
  connectionChange(state,status){
    state.connectionState = status;
  },
  
  makeSpectator(state){
    state.isSpectator = true;
  },
  
  endTurn(state){
    vm.$socket.emit('end-turn',{});
    state.team = [];
  },
  
  onboarding(state,data){
    state.onboarding = data
  },
  
  resetOnboarding(state){
    //console.log('set onboard to true');
    state.onboarding = false
  },
  
  'SOCKET_player-turn'(state,data){
   
   console.log('new player turn / equipment request',data);
   
    state.stage = {turn : data}
   // state.timer = 60 * 1000;
    //state.turn = 0;
  },
  
  'SOCKET_onboarding'(state){
     
     //console.log('start onboarding',data);
     
      state.onboarding = true;
      vm.$cookies.set('onboarding',false);
    
    },

  SOCKET_message(state,message,action){
    
    Object.assign(state.modal, message);
    
    if(action)
      state.modal.action = action
    
      state.modal.active = true;
    
    if(!action){
      
      if(messageTimeout)
        clearTimeout(messageTimeout)
      
      messageTimeout = setTimeout(() => {
        state.modal.active = false;
      }, 3000)
      
    }
    
  },
  
  start(state){
   // console.log('called complete')
    Vue.$cookies.set('onboarding','done')
    state.onboarding = false;
  },
  
  clearOnboarding(state){
    
    state.onboarding = false;
  },
  
  
  message(state,message,action){

    Object.assign(state.modal, message);
    
    if(action)
      state.modal.action = action
    
      state.modal.active = true;
    
    if(!action){
    
  
      
      if(messageTimeout)
        clearTimeout(messageTimeout)
      
      messageTimeout = setTimeout(() => {
        state.modal.active = false;
      }, 3000)
      
    }
    
  }, 

  discard(state, card){
 //   console.log('card',card)
    state.stage.turn = false;
    vm.$socket.emit('discard-card',{card : card.id});
    
  },
  serveClient(state, client){
    const record = state.clients.find(item => item.id === client.id);
    var player = state.players.find(player => player.id == state.id);
    var team = state.team.slice();
    team.push(player.id)
 //   console.log('team?',team);
    vm.$socket.emit('serve-client',{client:client.id,team:team});
    
    if(record){
      Object.assign(record,{treated : true, served : true})
      state.team = [];
    }
  },   
  register(state,data) {
  //  console.log('state set',data)
    //Object.assign(state,{id : data})
     state.id = data.id;
  },
  change(state, name) {
   state.name = name
   
  },
  play(state,data){
   // console.log('user made play',vm);
    vm.$socket.emit('new-play',data);
  },
  stage (state,data){
    Object.keys(data).forEach((key) => {
      
      state.stage[key] = data[key];
      
    })
  },
  profile(state,data) {
    
    var player = state.players.findIndex(player => player.id == state.id);
    
    if(player !== false){
    //  console.log('updating player', player, data)
      
      Object.assign(state.players[player],data);
      vm.$socket.emit('profile',data);
      
    }else{
      console.warn('profile missing player',state.id,player,state.players[0].id)
    }
    
  },  
  'SOCKET_game-over'(state,winner){
      console.log('WINNER ',winner)
      var player = state.players.find(player => player.id == winner);
      
      state.gameOver = true;
      
      var message = {
        active : true,
        cta: 'ok',
        ticker : true,
        title: player.name +' Wins!',
        message : 'After '+player.turns+' turns ' + player.name + ' has reached ' + state.levels.slice(-1)[0],
        action : function(){
          Vue.set(state,'modal',false)
          router.push('Scoreboard').catch((err) => {
            console.warn('already on ', 'scoreboard' ,err)
            });
        }
      }
      console.log('got to scoreboard');
      
      setTimeout(function(){
        
        router.push('Scoreboard').catch((err) => {
          console.warn('already on ', 'scoreboard' ,err)
          });
      },30 * 1000)
     
      
      Vue.set(state,'modal',message)
  },
  SOCKET_newplayer() {
       // console.log('NEW PLAYER!!',name)
       //  state.players.push({id: state.nextID++, name : name })
  },
  SOCKET_sync(state,data) {
        console.log('sync request ','nm', data)
        
        //syncReady
        Vue.set(state,'syncReady',true)
        
        if(data.message){
          console.log('new message')
          Vue.set(state,'modal',data)
        }
        
        if(data.theme && state.theme == null){
          console.log('set theme',data.theme)
          Vue.set(state,'theme',data.theme)
        }
        
        if(data.id){
          console.log('set theme ',data.id);
          Vue.set(state,'theme',data.id)
        }
        
        if(data.timer){
          console.log("timer set to ",data.timer * 1000);
          Vue.set(state,'timer',data.timer * 1000)
        }
        
        //game data only set once
        if(data.cards){
       //  console.log('pop cards',data.cards)
       //  console.table(data.cards.map((card) => card.id+' '+card.title))
          Vue.set(state, 'cards', data.cards)
        }
        
        if(data.levels){
         //     console.log('pop cards',data.cards)
             // console.table(data.players.map((player) => player.id+' '+player.name))
              Vue.set(state, 'levels', data.levels)
            }
        
        if(data.clients){
      //    console.log('pop clients',data.clients)
      //    console.table(data.clients.map((client) => client.id+' '+client.assigned))
          Vue.set(state, 'clients', data.clients)
        }
        
        if(data.dictionary){
       //  console.log('pop dictionary',data.dictionary)
         // console.table(data.players.map((player) => player.id+' '+player.name))
          Vue.set(state, 'dictionary', data.dictionary)
        }
        
        if(data.players){
      //    console.log('UPDATE players',data.players)
       //  console.table(data.players.map((player) => player.id+' '+player.name+' '+player.status))
          Vue.set(state, 'players', data.players)
        }
        
        if(data.stage){
       //  console.log('UPDATE stage',data.stage)
          Vue.set(state, 'stage', data.stage)
        }
        
        if(data.currentPlayer){
       //  console.log('player turn',data.currentPlayer)
          Vue.set(state, 'currentPlayer', data.currentPlayer)
        }
        
        if(data.gameState){
      //    console.log('g',data.gameState);
         // router.push(data.gameState);
        }
        
        if(data.currentState){
         // console.log('game state',data.currentState);
          
          if(data.currentState == 'game'){
            
            router.push(data.currentState).catch(() => {
            //  console.warn('already on ', data.currentState ,err)
              });
          }
        }

  }
        
        
        
 },
 getters: {
  canPlay : (state) => {
    console.log('can play', state.id , state.currentPlayer.id )
   console.log('is active',state.id,state.currentPlayer.id)
   
    return state.currentPlayer && state.currentPlayer.id === state.id && !state.gameOver;
  }, 
  session : (state) =>{
    
    return state.syncReady;
    
  },
  modal : (state) =>{
    
    return state.modal;
    
  },
  dictionary : (state) => (word) => {
    
    if(state.dictionary[word])
      return state.dictionary[word];
    else
      return 'Missing word';
    
  }, 
  player : (state,getters) => {
    var player = state.players.find(player => player.id == state.id);
   
    if(player){
     
       player.equipmentList = getters.equipment(player.equipment).filter( (item) => { 
 
        if(typeof item !== 'undefined') 
           return item.apprentice !== true
       
       });
       player.apprenticeList = getters.equipment(player.equipment).filter( (item) => { 
         
         if(typeof item !== 'undefined') 
             return item.apprentice == true
       
       });
       player.clientList = getters.clients(player.clients);
     
       
       
       
       player.role = getters.level(player.level);
     
       return player;
     
    }else{
      console.warn('profile missing player',state.id)
      return false;
    }
  }, 
  
  
  team :(state) => {
    
    var players = state.team.slice();
    
    players.push(state.id);
    
    var equipment = [],
        level = 0;
        
    players.map( (id) => {
      var player = state.players.find(player => player.id == id);

      if(player){
       
         equipment = equipment.concat(player.equipment);
         
      
           level += player.level;
      }else{
        console.warn('missing player!', id, state.players.map( (player ) => player.id));
      }
      

    })

   // console.log('we have',players.length,equipment.length)

    return {
      equipment : equipment,
      level : level
    }
    
    
  },
  connectionState : state => state.connectionState,
  isSpectator : state => state.isSpectator,
  syncReady : state => state.syncReady,
  timer : state => state.timer,
  id: state => state.id,
  name: state => state.name,
  stage: state => state.stage,
  cards: state => state.cards,
  theme: state => state.theme,
  //trade: state => state.trade,
  onboarding: state => {
    console.log('onboarding is',state, state.onboarding)
    return state.onboarding;  
  
  }
  ,currentPlayer: (state) => {
    
    
    return state.currentPlayer
  
  },
  
  firstRound : (state) =>{
  // console.log('g',getters.canPlay , state.stage.client)
   var player = state.players.find(player => player.id == state.id);
   
   if(player && player.turns < 2 && state.stage.client === false){
     return true;
   }else{
     return false;
   }
    
 }, 
  
  getProfile: (state,getters) => (id) =>{
    
    
    var player = state.players.find(player => player.id == id);

    if(player){
     
       player.equipmentList = getters.equipment(player.equipment).filter( (item) => { return item.apprentice !== true});
        player.apprenticeList = getters.equipment(player.equipment).filter( (item) => { return item.apprentice == true});
       player.clientList = getters.clients(player.clients);
      // player.skillsList = getters.skills(player.skills);
      
       return player;
     
    }else{
      console.warn('profile missing player',state.id)
    }
  },
  client: (state ) => (id) => {
    
    var client = state.clients.find(client => client.id == id);
     
    
    
    if(client){
    
      
      return client;
    }
    else
      console.warn('client - missing client',client.id)
  
  },
  canServe : (state,getters) => (id)  => {
         
      var client = state.clients.find(client => client.id == id);
      var team = getters.team;
      
      var serve = true,
      have = [],
      need =[],
      reason = [];
      
      var clientEquipment = getters.clientEquipment(id);
      
     // console.log('tem level is',team.level);
      
      if(client.level > team.level){
        serve = false;
        reason.push('an experience level of '+client.level+' or higher');
      }

      clientEquipment.map((item) => {
        
        if(item.available == false){
          serve = false
          need.push(item.name)
        }else{
          have.push(item)
        }
        
      })
      
      var response = reason.join(' and ');
      
      if(need.length > 0)
        response += ' and ' + need.join(', ')
      

      return {list : clientEquipment, have : have, require : require, serve : serve, reason : response, level : team.level};

    
  }, 
  clientEquipment : (state, getters) => (id)  => {
    var client = state.clients.find(client => client.id == id);

    var team = getters.team;
    

    
    var playerEquip =  [];

    if(typeof team !== 'undefined' && team.equipment){
        playerEquip = team.equipment.map((id) => {
       
        var item =  state.cards.find((card) => {return card.id == id})

     
        if(item)
          return { name : item.name, type_id : item.type_id, id: item.id, used : false};
        else
          console.warn('missing ',id);
      
      })
  }
    
    
    client.equipment.map((item) => {
      item.available = false;
    })
    
    playerEquip.map((subitem) => {
      subitem.used = false;
    })

    var equip = [];
        
    if(client){
      client.equipment.map((item) => {
        
        playerEquip.map((subitem) => {
           
           if(subitem.type_id == item.type_id)
          //   console.log(subitem.name , ' , ' ,item.name);
           
           if(subitem.type_id == item.type_id && item.available != true && subitem.used != true){
             item.available = true;
             subitem.used = true;
           }
         })
        equip.push(item);
      })
      
     
      
      return equip;
    }
    else
      console.warn('client - missing client',client.id)
    
  },
  avatars: state => state.avatars,
  players: (state) => (active) => {
    var players =  state.players.map((player) =>{
      
      player.role = state.levels[player.level - 1];
      return player;
    })
    
    if(active === true){
      players = players.filter((player) => {return player.assigned === true})
    }
    
    return players;
  
  },
  level : (state) => (level) =>{
    
    return state.levels[level];
    
  },
  equipment : (state) => (cards) =>{
   

   
    var cardList =  cards.map((id) => {
      
      var cc = state.cards.find((card) => {return card.id == id})
   //   console.log('show cards',state.cards.length)
     // console.table(state.cards.map(card => card.id))
      
      if(cc){
        return cc;
      }
      else{
        console.warn('missing card',id)
      }
      
      return 
    })
    /*
    cardList.map((item) => {
      item.tempid = Math.random();
    })
    */

    return cardList;
  },
  skills :(state) =>{
    var player = state.players.find(player => player.id == state.id);
    var cards =  player.skills.map((id) => {
      return state.cards.find((card) => {
        return card.id == id
      })
    })
    
   
   
    
    return cards;
  },
  clients: (state) => (clientList) =>{
    
    
    if(!clientList)
        return state.clients.filter((client) => {return client.served != true});
    else{
      
      var cardList =  clientList.map((id) => {
        
        return state.clients.find((client) => {
      //   console.log('match',client.id , id)
          return client.id == id
        
        })
       // return state.clients[id];
      })
      
      cardList = cardList.filter( (client) => {
        if(client)
          return client.served != true;
        else
          return false;
      })

      return  cardList;     
    }

  
    }
  }
 });
    