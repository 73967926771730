<template>
  
<div class="profile-avatar"
  :class="{
      'extend' : extend,
      'activated' : activated,
      'inactive' : (profile.status == 0),
      'turn' : (profile.status == 2),
      'you' : (this.profile.id == this.$store.getters.id)
    }"
  
  @click="callAction"
  
  >
  <div class="profile-avatar-frame">
    <img v-bind:src="'avatars/' + profile.avatar" />
    <a @click.stop="recruit()" class="recruit" v-if="$store.getters.canPlay && this.profile.id != this.$store.getters.id && context !=='profile'">Recruit</a>
  </div>
  <p class="name" v-if="this.profile.id != this.$store.getters.id || context ==='profile'"> {{ profile.name }}</p>
  <p class="name" v-if="this.profile.id == this.$store.getters.id && context !=='profile'" >You</p>
  <p class="extended" v-if="context !=='profile'"> 
    {{ profile.role }} 
    <span>Level {{ profile.level}}</span>
    
  </p>
  
</div>  
</template>

<script>

import { bus } from '../main'

export default {
  name: 'Player',
  props: {
  profile: Object,
  action : Function,
  extend: Boolean,
  activated : Boolean,
  context : String
  },
  methods :{
    
    recruit(){
      console.log('call recruit','seller',this.$store.getters.player.name, 'buyer', this.profile.name);
      
      
      this.$store.dispatch('recruit', {seller: this.$store.getters.player.id, buyer : this.profile.id})
      
      bus.$emit('start-trade');
    },
    
    callAction(){
      
      if(this.action && ((this.profile.id != this.$store.getters.id) || (this.context == 'profile'))){

        this.action(this.profile.id);
      }
      
      
    }
    
  }
}
</script>

