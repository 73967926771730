<template>
<div class="panel room">
	<div class="players">
		<Player v-for="player in $store.getters.players(true)" v-bind:key="player.id"  :profile="player" v-on:starttrade="closeOverlay()" :activated="player.id == overlay.id" :action="showOverlay" :extend="true" />
	</div>
  <div class="player-panel" v-if="overlay">
    <a class="close" @click="closeOverlay()"></a>
    <Cards :player="overlay" />
  </div>
  
  
</div>	
</template>

<script>
import { store } from '../store/store'
import { bus } from '../main'
import Player from './Player.vue';
import Cards from './Cards.vue';

export default {
  name: 'Room',
  store,
  props: {
	name: String,
  },
  components: {
    Player,
    Cards
  },
  created(){
  
    bus.$on('start-trade',() => {
      console.log('start trade clear profile', this)
      this.activated = false;
      this.overlay = false;
    })
  
  },
  methods : {
    closeOverlay(){
    console.log('close!')
      this.overlay = false;
    },
    
    showOverlay(player){
      
      
      var profile = this.$store.getters.getProfile(player);

      if(profile.assigned !== false){
        this.activated = true;
        this.overlay = profile; //this.$store.getters.getProfile(player);
      }
    }
    
  },
  data : function(){
    
    return {
      overlay : false
    
    }
    
  }
}
</script>

