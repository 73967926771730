import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import { store } from './store/store'
import VueRouter from 'vue-router'
import VueSocketIO from 'vue-socket.io'
import VueCookies from 'vue-cookies'
import VueConfetti from 'vue-confetti'
import router from './router';



import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: "https://57cb345b054f413eaecc96211636f921@o512078.ingest.sentry.io/5610626",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  tracesSampleRate: 1.0,
});




Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueCookies)
Vue.use(VueConfetti)


Vue.$cookies.config('1d');

var port = '';

if(window.location.hostname == 'localhost'){ 
  port = ':2090'
  port
}

Vue.use(new VueSocketIO({
  debug: false,
  connection: 'https://apprenticerace.glasgowsciencecentre.org:443', //window.location.protocol+'//'+window.location.hostname+port,
  vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
  },
 // options: { path: "/" } //Optional options
}))

export const bus = new Vue();

export default new Vue({
  router,
  store,
  render: h => h(App),
  data (){
    
    return {
      
      heartbeat : null

      
    }
    
  },
  sockets: {
    disconnect : function(){
      this.$store.commit('connectionChange',false);
    },
    connect: function () {
        console.log('socket connected Login')
        this.$store.commit('connectionChange',true);
        if(Vue.$cookies.isKey('onboarding')){
          console.log('HAS DONE')
          this.$store.commit('onboarding',false);
        }

        if(Vue.$cookies.isKey('session')){
          var session = Vue.$cookies.get('session')
          this.$socket.emit('resume',session);
         
          console.log('restart session', session);
        }else{
          console.log('no cookie')
          router.push('/').catch((err) => {
            console.log('already on login',err)
            });
        }
        
      
        
        
    },
    restart: function () {
      console.log('restart - remove cookie')
      this.$cookies.remove('session');
      this.$store.commit('gameReset');
      clearInterval(this.heartbeat)
      this.$router.push('/').catch(() => {
        console.log('already on root')
    });
    },
    welcome: function (data) {
        
        this.$store.commit('register', {id :data.id})
        Vue.$cookies.set('session',data.id)
        Vue.$cookies.set('loggedin','true')
        console.log('welcome',data,data.status == -1)
        
        if(data.status === 0){
            router.push('Profile').catch((err) => {
              console.log('already on profile',err)
          });
        }else{
    
        
        if(data.status === -1){
          console.log('send to lobby')
          router.push('Lobby').catch((err) => {
              console.log('already on lobby',err)
          });
        }
     
        
        if(data.inGame !== true){  
          console.log('not in game go to lobby')
          router.push('Lobby').catch((err) => {
            console.log('already on lobby',err)
            });
        }
        
        if(data.inGame === true){  
          console.log('send to game')
          router.push('Game').catch((err) => {
            console.log('already on game',err)
            });
        }
        
      }
      /*
      var self = this
      this.heartbeat = setInterval(function(){
        if(Vue.$cookies.isKey('loggedin')){
          self.$socket.emit('heartbeat',data.id);
        }
          
        
      },30 * 1000)  
      */
    }
},
}).$mount('#app')

