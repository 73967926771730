<template>
  <div class="login">

      <div class="login-left">
      <h1 class="login-left-logo">{{ gameName }}</h1>  
      <p v-if="!spectator">{{ prompt }}</p>
      
      <p v-if="spectator">Log in as spectator</p>
      <Error v-bind="errors" /> 
      <input v-model="code" name="name" placeholder="enter your code" class="userinput" > <button @click="login" class="button">Join</button>
      </div>
      
      <div class="login-illustration" ></div>
  </div>
</template>

<script>

import Error from '../components/Error.vue';

export default {
  name: 'Login',
  props: {
  
  },
  components: {
    Error
  },
  sockets: {
      connect: function () {
          console.log('socket connected Login')
      },
      loginerror : function (data) {
          console.log('login fail',data)
          this.errors.message = data.message;
      }
  },
  created : function(){
      let uri = window.location.href.split('?');
    
   
      if (uri.length == 2)
      {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function(v){
          tmp = v.split('=');
          if(tmp.length == 2)
          getVars[tmp[0]] = tmp[1];
        });
        
        if(getVars.room){
          var code = getVars.room.replace('#/','')
          this.code = code;
        }
        console.log('SPEC?',getVars.spectate)
        if(getVars.spectate){
          var spectator = getVars.spectate.replace('#/','')
          console.log('spec',spectator)
          this.$store.commit('makeSpectator',true);
          this.spectator = true;
        }
        
        if(getVars.theme){
          var theme = getVars.theme.replace('#/','')
          console.log('add theme : '+  theme);
          var link = document.createElement( "link" );
          link.href = "/custom/"+ theme + ".css";
          link.type = "text/css";
          link.rel = "stylesheet";
          link.media = "screen,print";
          
          document.getElementsByTagName( "head" )[0].appendChild( link );
        }
       
        
      }
  },
  data : function(){
    
    return {
      code : '',
      name : '',
      spectator : false,
      errors : {message :'', active : false},
      'gameName': 'Apprentice Race',
      prompt: 'Enter your supplied code to log in to the game' //this.$store.getters.dictionary('intro')
    }
    
  },

  methods: {
    changed: function(event) {
      this.$store.commit('change', event.target.value)
    },
    
    login: function() {
      console.log('code is',this.code)
      this.$cookies.remove('onboarding')
      
      this.$store.commit('resetOnboarding');
      this.$socket.emit('login', { code : this.code , spectator : this.spectator})
    }
    
  },
  computed: {
    
    newname : {
        get : function(){
          return name;
        },
        
        set : function(new_name){
            this.$store.commit('add', new_name)
        }
    }
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
