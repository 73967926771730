<template>
    <div class="droppable"
        :class="{'over' : over, 'dropped' : dropped, 'inactive' : !active}"
        :data-type="accept"
        @drop="drop"
        @dragover="dragover"
        @dragenter="dragenter"
        @dragleave="dragout"
    >
        
            
    </div>
    </template>
    
    <script>
        
    export default {
      name: 'Drop',
      props: {
        action: Function,
        accept : Number,
        args : Object
      },
      data (){
      
          return {
              over : false,
              dropped : false,
              active : true
          }
      
      },
      methods: {
          drop (event){
            event.preventDefault();
              
            var selectedType = null;

            if(event.dataTransfer.types.length > 0)
              selectedType = event.dataTransfer.getData("text/plain"); //parseInt(event.dataTransfer.types[0]);

                this.active = false;
                
                if(this.action){
                    this.action(selectedType,this.args);
                }
   
            
            this.over = false;
          },
          dragover (event){
            event.preventDefault();
            this.over = true;
   
    
        },dragenter (){
        },
         dragout (event){
              event.preventDefault();
              this.over = false;
      
        }
      }
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    
    </style>
    