<template>
    
    
    <div class="game">
        <Modal :card="this.connecting" v-if="$store.getters.syncReady != true"></Modal>
        <Timer :timer="$store.getters.timer" v-if="$store.getters.canPlay"/>
        <Room />
        <Stage />
        <Cards :player="$store.getters.player" v-if="$store.getters.player" :dashboard=true />
    </div>

</template>
    
    <script>
    
    import Room from '../components/Room.vue';
    import Stage from '../components/Stage.vue';
    import Cards from '../components/Cards.vue';
    import Timer from '../components/Timer.vue';
    import Modal from '../components/Modal.vue';
    export default {
      name: 'Game',
      props: {
        msg: String,
        name: String
      },
      components: {
          Room,
          Stage,
          Cards,
          Timer,
          Modal
        },
      sockets: {
          connect: function () {
              console.log('socket connected HWspo  ')
          },
          newuser: function (data) {
              console.log('broadcast',data)
              this.$store.commit('add', data)
          }
      },
      created : function(){

         
        if(!this.$cookies.isKey('session')){
           console.log('no session')
           this.$socket.emit('heartbeat',this.$store.getters.id); 
        }
         
      },
      data : function(){
        
        return {
          test : 'test',
          connecting: {
              title : 'Connecting to game',
              message : 'connecting...',
              cta : false,
              active : true
          }
        }
        
      },
      methods: {
        
        
      },
      computed: {
        
        
      }
    }
    </script>

    