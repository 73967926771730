<template>
<div class="panel cards"

  :class="{'active':$store.getters.canPlay,'trade':$store.getters.trade.complete, 'onboarding' : $store.getters.onboarding}"
>
  <div class="skills">
    <h3>{{ $store.getters.dictionary('skills') }}</h3>
    <div class="skills-stack" v-if="player.skills">
     <div class="card">
       <div class="cardPadding"><h3>{{ player.skills.length }}</h3></div>
     </div>
     <a @click="upgrade()" class="upgrade" v-if="player.skills.length > 2 && dashboard && $store.getters.canPlay">{{ $store.getters.dictionary('trade in skills') }}</a>

    </div>
  </div>
  <div class="equipment">
    <h3>{{ $store.getters.dictionary('equipment') }}</h3>
    <div class="card-slots">
      <div class="deal-cards" v-if="player.equipment">
      <div class="slot" v-for="n in 4" :key="n">
        <Drop :args="player.equipmentList[n-1]" :action="swapCard" >DROP</Drop>
        <Card :card="player.equipmentList[n-1]" v-if="player.equipmentList[n-1]" :action="expand" > </Card>
      
      </div>
      </div>
      <div class="apprentice-cards">
        <h4>{{ $store.getters.dictionary('apprentice equipment') }}</h4>
        <ul>
          <li v-for="kit in player.apprenticeList" :key="kit.id">{{ kit.name }}</li>
        </ul>
        
       <!-- <a @click="recruit()" class="recruit" v-if="$store.getters.canPlay && !dashboard">Recruit</a> -->
      </div>
    </div>
  </div>

  <div class="clients" v-if="player.clientList">
    <h3>{{ $store.getters.dictionary('clients') }}</h3>
    <div class="patient-cards">
      <Client v-for="client in player.clientList" :key="client.id" :client="client" :action="pickClient" > </Client>
      <!--
      <div class="Client card" v-for="n in (6 - player.clientList.length)" :key="n" > </div>
    -->
    </div>
  </div>
  
</div>  
</template>

<script>

import Card from './Card.vue';
import Drop from './Drop.vue';
import Client from './Client.vue';
import { bus } from '../main'

export default {
  name: 'Cards',
  props: {
    player: Object,
    dashboard : Boolean
  },
  methods: {
    pickClient(client){
    console.log(this.$store.getters.stage, client.expanded != true , this.$store.getters.canPlay)
      
      if(client.expanded != true && this.$store.getters.canPlay)
        this.$store.dispatch('selectClient',client.id)
    },
    expand(card){
      console.log('tog',card.expand)
      card.expand = !card.expand;
    },
    recruit(){
      console.log('call recruit','seller',this.$store.getters.player.name, 'buyer', this.player.name);
      bus.$emit('start-trade');
      this.$store.dispatch('recruit', {seller: this.$store.getters.player.id, buyer : this.player.id})
    },
    upgrade(){
      console.log('upgrade')
        this.$store.dispatch('upgrade',{});    
    },
    swapCard(newCard, currentCard){
      console.log('new swap', newCard, currentCard)
     
  
     
      var swapID = false
      if(currentCard)
        swapID = currentCard.id
      
      var client = this.$store.getters.player.clientList[this.$store.getters.player.clientList.length - 1];
     
      if(client)
        this.$store.dispatch('selectClient', client.id);

      this.$store.commit('assignTrade',newCard)
      
      this.$store.dispatch('choosecard',{player:this.$store.getters.player.id,newCard:newCard,currentCard:swapID});  
    }
  },
  components: {
      Card,
      Drop,
      Client
    },
    computed: {
      // a computed getter
      equipment: function () {
        // `this` points to the vm instance
        var playerCards = this.$store.getters.player
        console.log('cairds',playerCards,playerCards.equipment.length)
        
        return this.$store.getters.equipment(playerCards.equipment);
      }
    }
}
</script>


