<template>

<div class="lobby">
  <div class="intro">
    <h1>{{ title }}</h1>
    <p> {{ message }}</p>
  </div>
  <div class="players">
    <Player v-for="player in $store.getters.players(false)" v-bind:key="player.id"  :profile="player"  :context="'profile'"  :action="edit" />
  </div>

</div>

</template>

<script>

import Player from '../components/Player.vue';
import router from '../router';

export default {
  name: 'Lobby',
  props: {
    msg: String,
    name: String
  },
  components: {
    Player
  },
  sockets: {
      connect: function () {
          console.log('socket connected HWspo  ')
      },
      newuser: function (data) {
          console.log('broadcast',data)
          this.$store.commit('add', data)
      }
  },
  data : function(){
    
    return {
      title : 'Waiting Room',
      'message' : 'We are waiting for all the players to join'
    }
    
  },
  methods: {
    
    edit(){
      console.log('click')
      router.push('Profile').catch((err) => {
        console.log('already on profile',err)
        });
    }
    
  },
  computed: {
    
    
  }
}
</script>


