import vm from '../main';
import { bus } from '../main'

export const Trade = {
          state: () => ({ 
              
              trade : false
              
              }),
          mutations: { 

              trade(state, data ){
                  
                  console.log('trade action',data) 
                  
                  
                  bus.$emit('start-trade');
                  
       
                  
                  if(data.action == 'replay'){
                            
                       state.trade ={
                              
                              buyer: data.buyer,
                              seller : data.seller,
                              replay : data.replay,
                              cards : data.cards   
                              };  
                           
                           if(data.replay == 'accept' || data.replay == 'reject')   
                            setTimeout(() => {
                              state.trade = false;
                              }, 6000)  
                            
                  }
                 
                  
                  if(data.action == 'reject'){

                    
                    if(data.stage == 'request')
                      state.trade ={
                        reject: {
                        title: 'Offer rejected',
                        body: data.buyer.name + ' rejected your offer'
                      }
                    }
                      
                    if(data.stage == 'offer')
                      
                      state.trade = {
                        reject :{
                          title: 'Offer rejected',
                          body: data.seller.name + ' rejected your offer'
                        }
                      }
                    
                    
                     setTimeout(() => {
                      state.trade = false;
                    }, 4000)
                    
                    
                  }
                  
                  if(data.action == 'request'){
                    
                    console.log('make request',data)
                    
                    
                    
                    state.trade = {
                       seller : data.seller,
                       client: false
                     }
               
                  }
                  
                  if(data.action == 'offer'){
                    console.log('make offer',data)
                    vm.$socket.emit('trade',data);
                    
                  }
                  
                  
                  
                  if(data.action == 'recieve-offer'){
                    console.log('recieve offer',data)
                   
                   state.trade = {
                        buyer : data.buyer,
                        cards : data.cards
                      }
                   
                    
                  }
                  
                  if(data.action == 'accept'){
                    console.log('accept offer',data)
                   state.trade =  false;
                    vm.$socket.emit('trade',data);
                    
                  }
                  
                  if(data.action == 'clear'){
                            console.log('accept offer',data)
                           state.trade =  false;
                            vm.$socket.emit('trade',data);
                            
                          }
                  
                  if(data.action == 'accepted'){
                    console.log('show accepted')
                    
                    state.trade =  {
                          complete: true,
                          sellerName: data.seller.name, 
                          cards : data.cards
                        };
                  }
              
                  
                },
                assignTrade(state,card){

                   if(state.trade && state.trade.cards){
                     
                     var indx = state.trade.cards.findIndex((item) => {
                       
                       return item.id == card;
                       
                     })
    
                     if(indx !==-1){
                       state.trade.cards.splice(indx,1);
                     }
                     
                     if(state.trade && state.trade.cards.length == 0){
                       state.trade = false;
                     }
                     
                   }
                },

                startTrade(state, trade){
                    
                    var player = state.players.find(player => player.id == state.id);
                    
                    state.trade  = {
                        buyer : player,
                        seller : trade.seller
                      }
                    
                    
                    vm.$socket.emit('new-play',{trade :{
                      buyer : player,
                      seller : trade.seller
                    }});
        
        
        
                  },
                  
                  cancelTrade(state,data){
                    console.log('call cancel',data)
                    state.trade  = false;
                    
                    if(data){
                      vm.$socket.emit('trade',data);
                    }
                
                
                  },
                  
                  clearTrade(state){
                       
                            state.trade  = false;
                                 
                        
                          },
              
              
           },
          actions: { 
            
            'recruit'({state, rootState} ,data){
                          
                          var buyer = rootState.players.find(player => player.id == data.buyer);
                          bus.$emit('start-trade');
                      
                          
                          state.trade ={
                               pending : {
                                         title: 'Asking ' +buyer.name+ ' for help',
                                         message: 'Waiting for an answer'
                               }     
                          }
                          
                          vm.$socket.emit('trade',{buyer : data.buyer, seller : data.seller, card : false});
                          //commit.commit('recruitid',id);
                          console.log('recruit in trade',{buyer : data.buyer, seller : data.seller, card : false})
                          
                        },
              
            'SOCKET_trade'({commit ,  state, rootState },data){

                

                if(data.buyer){
                    data.buyer = rootState.players.find(player => player.id == data.buyer)
                }else{
                    data.buyer = rootState.players.find(player => player.id == state.id)
                } 
                
                if(data.seller){
                    data.seller = rootState.players.find(player => player.id == data.seller)
                }else{
                    data.seller = rootState.players.find(player => player.id == state.id)
                }    
                    
                if(data.cards){
                   
                    var cardList =  data.cards.map((id) => {
                      return rootState.cards.find((card) => {return card.id == id})
                    })
                
                    cardList.map((item) => {
                      item.tempid = Math.random();
                    })
                    
                    data.cards = cardList;
                   
                }
                
                if(data.action == 'replay'){
                         commit('trade',data); 
                      }
                
                if(data.action == 'request'){
                   commit('trade',data); 
                }
                
                if(data.action == 'reject'){
                   commit('trade',data); 
                }
                
                if(data.action == 'recieve-offer'){
                     commit('trade',data); 
                    
                  }
            
                if(data.action == 'accepted'){
                     commit('trade',data);                     
                  }  
            },  
            
            'SOCKET_recruit'({rootState },id){
                 // commit.commit('recruitid',data.player)
            //     console.log('recruting',id.player)
                      if(!rootState.team.includes(id.player)){
                        rootState.team.push(id.player);
                      }else{
                        rootState.team.splice(rootState.team.indexOf(id.player), 1);
                      }
                        
                //      console.log('team is',rootState.team)
                    
                  
                  
                },
            
            'startTrade'(commit, player){
                   commit('startTrade', {seller : player});
               },
              
              
           },
          getters: { 
              
                            
               trade: state => state.trade
              
          }
}