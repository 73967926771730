<template>
    <div 
        @click="dismiss"
        class="error"
        :class="{ 'show' : active}"
        v-if="message != ''"
        >
        
        {{ message}}
       
    </div>    
    </template>
    
    <script>
        export default {
          name: 'Drop',
          props: {
            action: Function,
            message : String
          },
          data (){
          
              return {
                  over : false,
                  dropped : false,
                  active : true
              }
          
          },
          methods: {
              dismiss (event){
              console.log('e',event);
              }
        }
        }
    </script>