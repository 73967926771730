<template>
    <div class="timer"   v-if="countdown.warning == true">
        Your turn ends in <span>{{ msToTime(countdown.left) }}</span> 
    </div>    
    </template>
    
    <script>
    
    
    
    export default {
      name: 'timer',
      props: {
        timer: Number
      },
      created : function(){
          console.log('set',this.timer)
          this.countdown.left = this.timer;
          this.countdown.total = this.timer;
          this.countdown.warning = false;
         
          var self = this;
          console.log('starting countdown at ',this.timer)
         
          this.timerInterval = setInterval(function(){
             
              if(self.countdown.left <= 0){
                  clearInterval(self.timerInterval);
                 self.$store.commit('endTurn', {});
              }else{
                  self.countdown.left -= 500;  
              }
         
              
              if(self.countdown.left < self.countdown.total * 0.25){
                
                  self.countdown.warning = true;
              }
              
              
              
         
          },500)
          
      
      },
      beforeDestroy : function(){
          if(this.timerInterval){
              console.log('stop timer')
              clearInterval(this.timerInterval);
              }
      },
      data : function() {
        
        return {
          countdown : {
              left: 0,
              total : 0,
              warning : 0
          },
          formated : 0,
          timerInterval : null
         
            
              
           }
      },
      methods: {
         
         msToTime : function (duration) {
           var seconds = Math.floor((duration / 1000) % 60),
             minutes = Math.floor((duration / (1000 * 60)) % 60);
            // hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
         
          // hours = (hours < 10) ? "0" + hours : hours;
           minutes = (minutes < 10) ? "0" + minutes : minutes;
           seconds = (seconds < 10) ? "0" + seconds : seconds;
         
           return  minutes + ":" + seconds ;
         }
         
        }
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    
    </style>
    