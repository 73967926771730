<template>
  <transition name="up">
<div class="client"
        :key="client.id"
        @click="expand"
        :class="{ 'expanded' : expanded, 'served' : served}"
>
           <!-- <a @click="close" class="close" v-if="expanded">{{$store.getters.dictionary('close')}}</a> -->
              <div class="overview"  >
                <div class="client-avatar">
                  
                  <img v-bind:src="'avatars/' + client.avatar" class="photo" />
                  <h4 :class="{'can-serve':($store.getters.canServe(client.id).level >= client.level || client.treated) }">Level {{ client.level }}</h4>
                  <div class="effect"></div>
                </div>
                
                <h3 @click="toggle">{{ client.name }} </h3>
              
                <ul class="equipment-list" v-if="!expanded">
                  <li v-for="equipment in $store.getters.clientEquipment(client.id)" v-bind:key="equipment.id"
                    :class="{'available' : (equipment.available || client.served), 'unavailable' : (!equipment.available && !client.served)}"
                  >{{ equipment.name }}  </li>
                </ul>
            
              </div>
              
              <div class="extended-info" v-if="expanded">
                
                <div class="equipment">
                  <ul class="equipment-list" >
       
                    <li v-for="equipment in $store.getters.clientEquipment(client.id)" v-bind:key="equipment.tempid"

                      :class="{'available' : (equipment.available || client.served), 'unavailable' : (!equipment.available && !client.served)}"
                    
                    >{{ equipment.name }}</li>
             
                  </ul>
        
                </div>
                <Drop />

            </div>
        <div class="controls" v-if="!client.served">
          <a @click="serve" class="button" v-if="expanded && $store.getters.canServe(client.id).serve && $store.getters.canPlay">{{$store.getters.dictionary('serve')}}</a>
          
          <p class="error" v-if="expanded && !$store.getters.canServe(client.id).serve && $store.getters.canPlay">
            {{$store.getters.dictionary('cantserve')}} {{ $store.getters.canServe(client.id).reason }}
          </p>
        </div>
</div>
  </transition>
</template>

<script>

import Drop from './Drop.vue';
import {bus} from  '../main';



export default {
  name: 'Client',
  props: {
    client: Object,
    expanded : Boolean,
    action : Function
  },
  components : {
    Drop
  },
  mounted: function () {
    
  //  var clientCards = this.$store.getters.client
  //  var retrieved = this.$store.getters.equipment(clientCards.equipment);
   // this.equipment = retrieved;
  },
  
  created (){
    bus.$on('hide-client', (data) => {
      this.header = data;
    })
    
    
  },
  
  watch: { 
    client: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal.served, ' | was: ', oldVal.served)
      
      if(newVal.served != oldVal.served && newVal.served == true){
        
          this.served = true;
    //    var particlesT = new Particles('.effect',{direction:'left',color : 'gold', size: 1, speed:1 });
     //    particlesT.disintegrate();
       this.$confetti.start({defaultSize : 5});
       setTimeout(()=>{ this.$confetti.stop() },3 * 1000)
       
      }else{
        this.served = false;
      }
    }
  },
  
  computed: {


    
  },
  data : function(){
    
    return {
     //   equipment : {}
     served : false,
     particles : null
    }
    
  },
  methods: {
    
    toggle (){
  //    console.log('A',anime);

     // var particles = new Particles('.button');
 //    var particlesT = new Particles('.effect',{direction: 'top', color : 'gold', typse : 'triangle', size: 1.5, speed:2 });
  //   particlesT.disintegrate();
      
  //    console.log('toggle',this.particles,this.client.served)
   //     this.served = !this.served
    },
    
    close (){
      this.$store.dispatch('selectClient',false)
    },    
    serve (){
      
    //  var remaining = this.client.equipment.filter((item) => { return item.status != true})    
     // if(remaining.length < 1){
        this.$store.dispatch('serveClient',this.client)
       
       this.$confetti.start({defaultSize : 5});
        setTimeout(()=>{ this.$confetti.stop() },3 * 1000)
         this.served = true
    //  }
    //  else
    //    console.log('treat patient',remaining)
    },
    didDrop (card){
      console.log('Did drop',card)
      var match = this.client.equipment.find((item) => { return item.type_id == card && item.status != true})      
      
      if(match)
        match.status = true
      
      console.log(match);
      this.$store.commit('play',{'test' : 'test' });
    },
    expand (event){
      event.preventDefault();
      if(this.action)
        this.action(this.client)
      
    },
    dragover (event){
   // event.preventDefault();
    console.log('drop event',event)    
  }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

