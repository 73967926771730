<template>
<div class="panel turn">
      
      <div class="intro">
        <h1>{{ $store.getters.dictionary('its your turn') }}</h1>
  
        <p v-if="showSkills" v-html="$store.getters.dictionary('you have been awarded a new skill')">
   
        </p>
          <p v-if="showEquipment" v-html="$store.getters.dictionary('you have been awarded new equipment')">

      

          </p>
      </div>
       <div class="skills-panel" v-if="showSkills">
        
         <Card :card="skillCard" v-if="skillCard" > </Card>
         <button @click="next()" class="accept">Accept</button>
       </div>
      
      
      <div class="equipment-panel" v-if="showEquipment">
        <Card :card="equipmentCard" v-if="equipmentCard" :canDrag="true" :extend=true > </Card>
        <button @click="discard(equipmentCard)" class="reject">Reject</button>
      </div>
        
         
        
</div>
</template>

<script>

import Card from './Card.vue';


export default {
  name: 'Turn',
  props: {
    turn : Object
  },
  components : {
    Card
  },
  computed: {
    // a computed getter
    
    showSkills : function () {
      
      if(this.turn.skill)
        return true;
      else
        return false;
      
    },
    showEquipment : function (){
      
      if(this.turn.equipment)
        return true;
      else
        return false;
      
    },
    
    skillCard: function () {

     return this.$store.getters.cards.find((card) => {return card.id == this.turn.skill})
     // return null
    },
    equipmentCard: function () {

       return this.$store.getters.cards.find((card) => {return card.id == this.turn.equipment})
      //  return null;
      }
  },
  data : function(){
    
    return {
      cards : []
    }
    
  },
  methods: {
   
   next(){
       this.turn.skill = false;
       this.turn.equipment = false;
       
       var client = this.$store.getters.player.clientList[this.$store.getters.player.clientList.length - 1];
       
       if(client)
         this.$store.dispatch('selectClient', client.id);
    // this.showSkills = false
   //  this.showEquipment = true
   },
   discard(equipmentCard){
     
     this.$store.commit('discard',equipmentCard)
     
     var client = this.$store.getters.player.clientList[this.$store.getters.player.clientList.length - 1];
      
      if(client)
        this.$store.dispatch('selectClient', client.id);
     
   //  this.$store.getters.stage.turn = false;
     
   },   
    accept(card){
      var data = {
        player : this.$store.state.id,
        card : card.id,
        action : 'accept'
      }
      console.log('accepted card',card,data)
      
      },
    close(){
      this.$store.getters.stage.turn = false;
    },
    didDrop (event){
      console.log('accepted card',event)
    },
    dragover (event){
   // event.preventDefault();
    console.log('drag event',event)    
  }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
