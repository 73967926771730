<template>
  <div id="app">

    <header v-if="$store.state.isSpectator"> <!--  -->
      <a @click="endTurn()">End turn</a>
     
    </header>
      <Modal :card="$store.getters.modal"></Modal>
      

      <router-view></router-view>
      
      
      <div class="connection" v-if="!this.$store.getters.connectionState">Connection lost!</div>
  </div>
  
</template>

<script>
import { store } from './store/store'
import Modal from './components/Modal.vue';



export default {
  name: 'App',
  store,
  
  components: {
    Modal
  },
  computed: {
    theme () {
      return store.getters.theme
      // Or return basket.getters.fruitsCount
      // (depends on your design decisions).
    }
  },
  watch: {
    theme (newTheme, oldTheme) {
      // Our fancy notification (2).
      oldTheme;
      console.log(`Setting theme`);
      var rootStyles = document.querySelector(':root');
      
      for (const [key, value] of Object.entries(newTheme)) {
        console.log(`--${key}: ${value}`);
        rootStyles.style.setProperty('--'+key, value);
      }
      
      
    }
  },
  created: function(){
    console.log('theme is '+store.getters.theme);
    let uri = window.location.href.split('?');
    
    if (uri.length == 2)
    {
      let vars = uri[1].split('&');
      let getVars = {};
      let tmp = '';
      vars.forEach(function(v){
        tmp = v.split('=');
        if(tmp.length == 2)
        getVars[tmp[0]] = tmp[1];
      });
      
    
      
      if(getVars.theme){
        const regex = /(#\/[A-Z]+)/gi;
        var theme = getVars.theme.replace(regex,'')
        console.log('add theme : '+  theme);
        var link = document.createElement( "link" );
        link.href = "/custom/"+ theme + ".css";
        link.type = "text/css";
        link.rel = "stylesheet";
        link.media = "screen,print";
        
        document.getElementsByTagName( "head" )[0].appendChild( link );
      }
    }
    
  },
  methods: {
    endTurn : function(){
      console.log('end turn')
      this.$store.commit('endTurn', {})
    }
  },
  sockets : {
    
    connect: function () {
        console.log('socket connected')
    },
    reset: function () {
        console.log('session reset')
        this.$cookies.remove('session');
        this.$cookies.remove('onboarding');
        this.$router.push('/')
    },
    
  }
}
</script>

<style lang="scss">
  @import "./scss/base.scss";

</style>
